<template>
    <div>
        <!-- <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/warehouses" label="name" track-by="name" @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/suppliers" label="codename" track-by="codename" @selected="(val) => {
                        this.selectedSupplier = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>PO Type</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <POType :value="selectedPoType" base-url="/api/wms/v1/wms/purchase-type" 
                        label="name" 
                        track-by="name" 
                        :multiple="true" 
                        :allowEmpty="false"
                        @selected="(val) => {
                            this.selectedPoType = val;
                        }">
                    </POType>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div> -->
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="activeTab"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0"
                    >
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import Table from "./components/ExecutedTable.vue";
export default {
    components: {
        Table
    },
    data() {
        return {
            activeTab: "Executed",
            draw: 0,
            selectedSrcWarehouse: null
        }
    },
    methods: {
        // handleCreate() {
        //     this.$vs.loading();
        //     this.$router.push({
        //         name: this.$store.state.purchaseOrder.baseRouterName + "-create",
        //     });
        // },
        handleFilter() {
            this.draw++;
        },
    },
    mounted(){
        sessionStorage.setItem('activeTabPO', "5");
    },
    watch: {
        selectedPoType(v) {
            var poTypeIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) poTypeIds.push(e.id) });
            }
            this.selectedPoTypeIds = poTypeIds.join(",");
        },
    }
}
</script>